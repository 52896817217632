@import "../../scss/Global.scss";

.header {
  z-index: 4;
  position: relative;
  top: 0;
  display: flex;
  width: 100%;
  background-color: transparent;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;

  .logo {
    height: 90px;
    padding: 10px 0;
  }

  h3 {
    color: $colorButtons;
  }

  .desktop__links {
    z-index: 0;
    position: absolute;
    right: 0;
    top: 60px;
    transition: transform 0.2s ease-in;
    background-color: $colorButtons;
    display: flex;
    flex-direction: column;
    align-items: center;

    li,
    a {
      cursor: pointer;
      list-style: none;
      text-transform: uppercase;

      padding: 15px 20px;
      color: #ffff;
      text-decoration: none;
      width: 100%;
      transition: 0.3s;
      font-weight: 600;
      &:hover {
        background-color: $colorLightTwo;
      }
    }
  }

  a {
    text-decoration: none;
    color: #ffff;
  }
}

@media screen and(max-width: 450px) {
  .header {
    .logo {
      height: 75px;
    }
  }
}
