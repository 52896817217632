@import "../../scss/Global.scss";

.test {
  width: 800px;

  border-radius: 20px;
  padding-bottom: 20px;
  background-color: white;

  display: flex;
  justify-content: center;
  flex-direction: column;

  .questionNumber {
    border-radius: 20px 20px 0 0;
    background-color: $colorDark;
    padding-top: 20px;
    h3 {
      text-align: center;
      color: white;
    }
  }

  .questionContainer {
    padding: 20px 60px;

    legend {
      margin-bottom: 10px;
      text-align: center;
      font-weight: 600;
    }

    .image__container {
      min-height: 250px;
    }

    .sign {
      display: block;
      margin: 10px auto;
      width: 250px;
    }
    .question {
      margin-bottom: 20px;
      padding: 5px;
      display: flex;
      align-items: center;

      label {
        margin-left: 10px;
        cursor: pointer;
      }

      input[type="radio"] {
        border: 1px solid rgb(0, 0, 0);
        padding: 0.5em;
        width: 20px;
        height: 20px;

        cursor: pointer;
        -webkit-appearance: none;

        &:checked {
          background-color: $colorHeroBg;
        }
      }
    }

    .answers {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 800px) {
  .test {
    width: 95vw;

    .questionContainer {
      padding: 20px 10px;

      .sign {
        max-width: 200px;
      }

      legend {
        font-size: 15px;
      }

      label {
        font-size: 13px;
      }

      input[type="radio"] {
        width: 15px;
        height: 15px;
      }
    }
  }
}
