// COLOURS
/* $colorHeroBg: #6dc0d5;
$colorDark: #5a716a;
$colorLight: #b2cede;
$colorButtons: #ad7a99;
$colorLightTwo: #8cdfd6; */

$colorHeroBg: #dc2f02ff;
$colorDark: #370617ff;
$colorMedium: #e85d04ff;
$colorLight: #faa307ff;
$colorButtons: #f48c06ff;
$colorLightTwo: #ffba08ff;

$xiketic: #03071eff;
$dark-sienna: #370617ff;
$rosewood: #6a040fff;
$dark-red: #9d0208ff;
$rosso-corsa: #d00000ff;
$vermilion: #dc2f02ff;
$persimmon: #e85d04ff;
$tangerine: #f48c06ff;
$orange-web: #faa307ff;
$selective-yellow: #ffba08ff;
