@import "../../../scss/Global.scss";

.button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px;
  border: none;
  color: #ffff;
  font-weight: 600;
  border-radius: 20px;
  background-color: $colorButtons;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background-color: $colorLightTwo;
  }
}
