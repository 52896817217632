@import "../../scss/Global.scss";

.test {
  width: 800px;
  border-bottom: 1px solid black;

  display: flex;
  justify-content: center;
  flex-direction: column;

  &:last-child {
    border: none;
  }

  .questionNumber {
    border-radius: 20px 20px 0 0;
    background-color: $colorDark;
    padding-top: 20px;
  }

  .questionContainer {
    padding: 20px 60px;

    h3 {
      text-align: center;
    }

    legend {
      margin-bottom: 20px;
      /*  text-align: center; */
      font-weight: 600;
    }

    .image__container {
      min-height: 250px;
      padding-bottom: 20px;
    }

    .sign {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 250px;
    }
    .question {
      margin-bottom: 20px;
      display: flex;

      label {
        flex: 1;
        margin-left: 10px;
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;

        .checkmark {
          width: 20px;
          height: 20px;
          text-align: center;
        }
      }

      input[type="radio"] {
        border: 1px solid rgb(0, 0, 0);
        padding: 0.5em;
        width: 20px;
        height: 20px;

        cursor: pointer;
        -webkit-appearance: none;

        &:checked {
          background-color: $colorHeroBg;
        }
      }
    }

    .answers {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 800px) {
  .test {
    width: 95vw;
    .questionContainer {
      padding: 10px;

      h3 {
        font-size: 16px;
      }

      legend {
        font-size: 15px;
      }

      label {
        font-size: 12px;
      }

      input[type="radio"] {
        width: 15px;
        height: 15px;
      }

      .sign {
        max-width: 200px;
      }
    }
  }
}
