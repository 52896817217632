@import "../../../scss/Global.scss";

.spinner__container {
  position: relative;
  width: 100%;
  height: 250px;

  .spinner {
    position: absolute;
    border: 5px solid $colorHeroBg;
    border-top: 5px solid rgb(190, 190, 190);
    border-radius: 50%;
    left: 0;
    right: 0;
    text-align: center;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 800px) {
  .spinner__container {
    height: 200px;
  }
}
