@import "../../scss/Global.scss";

.answers {
  border: 1px solid black;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 100px;
  background-color: #ffff;
  max-width: 700px;

  .results {
    .pass {
      background-color: green;
      color: white;
      padding: 0 5px;
    }

    .fail {
      background-color: red;
      color: white;
      padding: 0 5px;
    }
  }

  button {
    margin-top: 20px;
  }

  p {
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
  }

  h4 {
    text-align: center;
  }

  .question {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .sign {
      max-width: 200px;
    }
  }

  .answer {
    padding: 10px 0px;
    border-bottom: 1px solid rgb(177, 171, 171);
    h3 {
      text-align: center;
    }
    .correct {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: space-between;
      margin: 10px 0px;

      /*  background-color: green;
      color: white; */
      &::after {
        content: "✔";
        font-size: 20px;
        color: green;
      }
    }

    .wrong {
      /* background-color: red;
      color: white; */
      display: flex;
      align-items: center;
      gap: 20px;
      margin: 10px 0px;
      justify-content: space-between;

      &::after {
        content: "❌";
        font-size: 15px;
        color: red;
      }
    }
    .correct__blue {
      margin: 10px 0px;
      font-weight: 600;
      color: $colorDark;
    }

    .bold {
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 500px) {
  .answers {
    margin-bottom: 20px;
    padding: 10px;

    h3 {
      font-size: 18px;
    }

    h4 {
      font-size: 13px;
    }

    .answer {
      span {
        font-size: 12px;
      }
    }
  }
}
