@import "../../scss/Global.scss";

.mocktest {
  margin: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $colorHeroBg;

  h3 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 570px) {
  .mocktest {
    margin: 20px;
  }
}
