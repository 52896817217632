@import "../../scss/Global.scss";

.popup {
  max-width: 500px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  .text__container {
    text-align: center;
    padding-bottom: 20px;

    span {
      font-weight: 600;
    }
  }

  .scores {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    color: $colorMedium;

    max-width: 200px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    h4 {
      margin-bottom: 5px;
    }

    .score {
      padding: 10px 0px;
    }
  }

  .notice {
    text-align: center;
    color: gray;
    font-size: 10px;
    margin-top: 10px;
  }
}
