@import "../../scss/Global.scss";

.login {
  display: flex;

  flex-direction: column;
  padding-bottom: 20px;
  h2 {
    display: inline-block;
    text-align: center;
    margin: 50px auto 50px auto;
    max-width: 550px;
    color: white;
    letter-spacing: 0.3px;
    font-size: 30px;
  }

  .login__body {
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 50px;
    border-radius: 20px;
    width: 500px;

    .showSignupContainer {
      text-align: center;
      margin-top: 10px;
      .question {
        color: #ffff;
        margin-right: 10px;
        margin-top: 10px;
      }

      .link {
        font-weight: 600;
        margin-top: 10px;
        text-align: center;
        cursor: pointer;
        color: $colorLightTwo;
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .login {
    h2 {
      font-size: 25px;

      margin: 20px 20px 20px 20px;
    }

    .login__body {
      width: 90vw;
      padding: 10px;
    }
  }
}
