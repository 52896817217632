.wrapper {
  background-color: white;
  border-radius: 20px;
  max-width: 800px;
}

@media screen and (max-width: 800px) {
  .wrapper {
    width: 95vw;
  }
}
