@import "../../scss/Global.scss";

.study {
  margin: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin-bottom: 20px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    background-color: white;
    border-radius: 30px;
    color: gray;
    font-weight: 600;
    padding: 10px 20px;

    li {
      list-style: none;
      a {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;

        &:hover {
          background-color: $colorHeroBg;
          color: white;
          border-radius: 5px;
        }
      }
    }
    .active {
      a {
        background-color: $colorHeroBg;
        color: white;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .study {
    margin: 20px 20px 20px 20px;
    .pagination {
      width: 99vw;
      gap: 5px;
      border-radius: 0;

      li {
        a {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
