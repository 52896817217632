@import "../../scss/Global.scss";

.login {
  display: flex;

  flex-direction: column;
  padding-bottom: 20px;

  .login__body {
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 50px;
    border-radius: 20px;
    width: 500px;

    .login__form {
      display: flex;
      flex-direction: column;

      h1 {
        text-align: left;
        margin-bottom: 25px;
        color: #ffff;
        text-align: center;
      }

      .or {
        line-height: 0.9px;
        text-align: center;
        width: 100%;
        border-bottom: 1px solid #e2e4e5;
        margin: 26px 0;

        span {
          color: #e2e4e5;
          background-color: #b02602;

          padding: 0 15px;
        }
      }

      input {
        outline-width: 0;
        height: 40px;
        margin-bottom: 14px;
        border-radius: 5px;
        border: none;
        padding: 5px 15px;
      }

      button {
        padding: 16px 20px;
        font-size: 1rem;

        border-radius: 5px;

        font-weight: 600;
        border: none;
        cursor: pointer;
        margin-top: 20px;
        transition: 0.3s;
        border: 2px solid $colorButtons;

        &:hover {
          background-color: #ffff;
          color: $colorButtons;
        }
      }

      .forgot__password {
        text-align: center;
        text-decoration: none;
        color: #d4d4d4;
        margin-top: 5px;
        &:hover {
          color: $colorButtons;
        }
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .login {
    margin-top: 20px;
    .login__body {
      width: 90vw;
      padding: 10px;
      .login__form {
        h1 {
          font-size: 25px;
        }

        button {
          font-size: 15px;
          padding: 10px 20px;
        }
      }
    }
  }
}
