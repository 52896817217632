.container {
  height: 150px;
  width: 400px;
  background-color: white;
  margin: auto;
  border-radius: 20px;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  text-align: center;
}

@media screen and (max-width: 415px) {
  .container {
    width: 90vw;
  }
}
