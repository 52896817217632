@import "../../scss/Global.scss";

.hero {
  background-color: $colorHeroBg;
  height: 830px;
  position: relative;
  background-image: url("../../assets/img/skyline.svg");

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  img {
    z-index: 2;
    display: block;
    position: absolute;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 350px;
  }

  .arch {
    z-index: 1;
    position: absolute;
    bottom: -850px;
    left: 50%;
    transform: translateX(-50%);
    width: 5000px;
    height: 1000px;
    background-color: $colorHeroBg;
    border-radius: 50% 50% 0 0;
  }

  .hero__text {
    margin-left: auto;
    margin-right: auto;
    color: #ffff;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding: 20px;
    max-width: 900px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;

    h1 {
      font-size: 40px;
      max-width: 700px;
      text-align: center;
      margin-bottom: 20px;
    }

    h2 {
      margin-bottom: 20px;
    }

    a {
      text-decoration: none;
      font-size: 20px;
      font-weight: 600px;
      padding: 10px 20px;
      background-color: $colorButtons;
      color: #ffff;
      font-weight: 600;
      border: none;
      border-radius: 20px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        background-color: $colorLightTwo;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .hero {
    height: 550px;
    img {
      max-width: 250px;
    }
    .hero__text {
      h1 {
        font-size: 20px;
      }

      h2 {
        font-size: 15px;
      }

      a {
        font-size: 15px;
      }
    }
  }
}
